// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "22.3.0",
  "buildNumber": "15301",
  "gitSha": "9d23ed19a9ea46eaf7f18bd16671ae0569871f53",
  "fullVersion": "22.3.0-15301",
  "formattedVersion": "22.3.0 (15301-9d23ed19a9ea46eaf7f18bd16671ae0569871f53)",
  "copyrightYear": "2022"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/22.3.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/22.3.0/${stripLeadingPrefix(suffix, '#')}`
}
    
